<template>
  <v-combobox
    label="Permission"
    :items="permissions"
    item-text="name"
    item-id="id"
    :hint="hint"
    persistent-hint
    clearable
    v-model="permission"
    @change="emitEvent"
  />
</template>
<script>
export default {
  name: "PermissionSelector",
  props: {
    value: {},
    hint: {
      default: () =>
        "Optional. Choose a permission to associate with this action. If the user does not have the desired permission, the button will be hidden",
    },
  },
  data() {
    return {
      permissions: [],
      permission: null,
    };
  },
  mounted() {
    this.fetchPermissions();
  },
  methods: {
    emitEvent() {
      this.$emit("update", this.permission && this.permission.id);
    },
    async fetchPermissions() {
      const permissions = await this.$store.dispatch("fetchAllPermissions");
      this.permissions = permissions;
      this.permission = permissions.find((item) => item.id == this.value);
    },
  },
};
</script>
