<template>
  <v-row dense aut-permission-row>
    <v-col cols="12" :lg="colWidth">
      <div class="py-2">
        <label>{{ entity.name }}</label>
      </div>
    </v-col>
    <v-col cols="12" :lg="colWidth" v-if="hasPermissionType('NA')">
      <v-checkbox
        class="behavior_centered"
        :key="refreshKey"
        dense
        aut-na-checkbox
        v-model="naPermission"
      ></v-checkbox>
    </v-col>
    <v-col cols="12" :lg="colWidth" v-if="hasPermissionType('R')">
      <v-checkbox
        class="behavior_centered"
        :key="refreshKey"
        dense
        aut-read-checkbox
        v-model="readPermission"
      ></v-checkbox>
    </v-col>
    <v-col cols="12" :lg="colWidth" v-if="hasPermissionType('W')">
      <v-checkbox
        class="behavior_centered"
        :key="refreshKey"
        dense
        aut-write-checkbox
        v-model="writePermission"
      ></v-checkbox>
    </v-col>
    <v-col cols="12" :lg="colWidth" v-if="hasPermissionType('E')">
      <v-checkbox
        class="behavior_centered"
        :key="refreshKey"
        dense
        aut-execute-checkbox
        v-model="executePermission"
      ></v-checkbox>
    </v-col>
  </v-row>
</template>
<script>
import { toInteger } from "lodash";
const debug = require('debug')('atman.permission_check')  // eslint-disable-line

const updatePermissions = (newPermission, value) => {
  let result = [];
  switch (newPermission) {
    case "NA": {
      result = value ? ["NA"] : [];
      break;
    }
    case "R": {
      result = value ? ["R"] : [];
      break;
    }
    case "W": {
      result = value ? ["R", "W"] : ["R"];
      break;
    }
    case "E": {
      result = value ? ["E"] : [];
      break;
    }
  }
  return result;
};
export default {
  name: "PermissionCheck",
  props: {
    entity: {
      type: Object,
    },
    permissions: {
      type: String,
    },
    types: {
      type: Array,
    },
  },
  data() {
    let permissionsList = [];

    if (this.permissions == "NA") {
      permissionsList = ["NA"];
    } else if (this.permissions == "") {
      permissionsList = [];
    } else {
      permissionsList = (this.permissions || "")
        .split("")
        .filter((item) => item == "R" || item == "W" || item == "E");
    }
    debug(`permissions,`, this.permissions, permissionsList);
    return {
      permissionsList,
      refreshKey: 1,
    };
  },
  mounted() {
    debug(
      `Permission Check`,
      `entity`,
      this.entity,
      `permissions`,
      this.permissions,
      `permissionsList`,
      this.permissionsList,
      `types`,
      this.types
    );
  },
  computed: {
    colWidth() {
      const result = toInteger(12 / (this.types.length + 1));
      debug(`colWidth`, result);
      return result;
    },
    readPermission: {
      get() {
        return this.permissionsList.includes("R");
      },
      set(value) {
        this.permissionsList = updatePermissions("R", value);
      },
    },
    writePermission: {
      get() {
        return this.permissionsList.includes("W");
      },
      set(value) {
        this.permissionsList = updatePermissions("W", value);
      },
    },
    executePermission: {
      get() {
        return this.permissionsList.includes("E");
      },
      set(value) {
        this.permissionsList = updatePermissions("E", value);
      },
    },
    naPermission: {
      get() {
        debug(`permissions List`, this.permissionsList);
        return this.permissionsList.includes("NA");
      },
      set(value) {
        this.permissionsList = updatePermissions("NA", value);
      },
    },
  },
  watch: {
    permissionsList() {
      const updatedPermissions = this.permissionsList.join("");
      debug(`Emitting update`, updatedPermissions);
      this.$emit(`update`, updatedPermissions);
    },
  },
  methods: {
    hasPermissionType(type) {
      const result = !!this.types.find(
        (permissionType) => permissionType.id == type
      );
      // debug(`Returning [${result}] for type: [${type}]`, this.types)
      return result;
    },
  },
};
</script>
