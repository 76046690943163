<template>
  <v-dialog
    v-model="display"
    width="800"
    v-if="fieldDefinition"
    @keydown.esc.stop.prevent="$emit('close')"
    @click:outside="$emit('close')"
  >
    <v-card aut-edit-permissions-dialog>
      <DialogTitle @close="$emit('close')" title="Configure Permissions" />
      <v-card-text>
        <div>
          <v-switch
            label="Enable Permissions"
            v-model="fieldDefinition._permissions.enabled"
          ></v-switch>
        </div>
        <v-tabs
          v-model="tabModel"
          @change="tabModel = $event"
          v-if="fieldDefinition._permissions.enabled"
        >
          <v-tab aut-permission-context="permission">Permissions</v-tab>
          <v-tab
            :aut-permission-context="context.id"
            v-for="(context, i) in permissionContexts"
            :key="i"
            >{{ context.name }}</v-tab
          >

          <v-tab-item>
            <div v-if="fieldDefinition._permissions.permission">
              <PermissionSelector
                v-model="fieldDefinition._permissions.permission.value"
                hint="Choose a permission"
                @update="updatePermission"
              />
              <v-autocomplete
                v-if="mode == 'field'"
                label="Behavior"
                v-model="fieldBehavior"
                item-text="label"
                item-value="id"
                :items="fieldBehaviors"
              ></v-autocomplete>
              <v-autocomplete
                v-else-if="mode == 'menu'"
                label="Behavior"
                v-model="menuBehavior"
                item-text="label"
                item-value="id"
                :items="menuBehaviors"
              ></v-autocomplete>
              <v-text-field
                v-if="showURL"
                label="Alternate Page"
                v-model="fieldDefinition._permissions.permission.url"
              ></v-text-field>
            </div>
          </v-tab-item>
          <v-tab-item v-for="(context, i) in permissionContexts" :key="i">
            <PermissionChecks
              :mode="mode"
              :permission_context="context"
              :definition="fieldDefinition"
              :current_permissions="currentPermissions(context.id)"
              @update="updateLocalDefinition"
            />
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('close')" aut-permissions-edit-cancel>
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          aut-action="permissions-edit-update"
          @click="updateFieldPermissions"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { defaultsDeep } from "lodash";
import { dialogMixin } from "@/components/mixin.js";
import { clone } from "@/util.js";
import PermissionChecks from "./PermissionChecks";
import PermissionSelector from "@/components/editor/PermissionSelector/PermissionSelector.vue";
const debug = require("debug")("atman.components.permission_editor"); // eslint-disable-line
export default {
  name: "PermissionEditor",
  mixins: [dialogMixin],
  components: {
    PermissionChecks,
    PermissionSelector,
  },
  props: {
    definition: {
      type: Object,
    },
    context: {
      type: String,
    },
    mode: {
      type: String,
    },
  },
  data() {
    return {
      fieldDefinition: defaultsDeep({}, clone(this.definition || {}), {
        _permissions: {
          enabled: false,
          roles: {},
          users: {},
          permission: {
            value: null,
            behavior: null,
            url: null,
          },
        },
      }),
      display: true,
      tabModel: null,
      showURL: false,
      permissionContexts: null,
      roles: [],
      users: [],
    };
  },
  watch: {
    "fieldDefinition._permissions.permission.behavior"() {
      this.showAlternateURL();
    },
  },
  computed: {
    fieldBehavior: {
      get() {
        const currentBehavior =
          this.fieldDefinition?._permissions?.permission?.behavior;
        return this.fieldBehaviors.find(({ id }) => id == currentBehavior);
      },
      set(val) {
        this.fieldDefinition._permissions.permission.behavior = val;
      },
    },
    menuBehavior: {
      get() {
        const currentBehavior =
          this.fieldDefinition?._permissions?.permission?.behavior;
        return this.menuBehaviors.find(({ id }) => id == currentBehavior);
      },
      set(val) {
        this.fieldDefinition._permissions.permission.behavior = val;
      },
    },
  },
  created() {
    this.fieldBehaviors = [
      {
        id: "hidden",
        label: "Hidden",
        hint: "Field will be present on the page but hidden",
      },
      {
        id: "ignored",
        label: "Ignored",
        hint: "Field will be removed from the page",
      },
      {
        id: "disabled",
        label: "Disabled",
        hint: "Field will be disabled",
      },
    ];
    this.menuBehaviors = [
      {
        id: "hidden",
        label: "Hidden",
        hint: "Field will be present on the page but hidden",
      },
      {
        id: "disabled",
        label: "Disabled",
        hint: "Field will be disabled",
      },
      {
        id: "url",
        label: "Navigate to Alternate URL",
        hint: "Navigate to a different URL",
      },
    ];
  },
  mounted() {
    debug(`in Permission Editor`, this.context, this.definition);
    this.fetchPermissionContexts();
    this.showAlternateURL();
  },
  methods: {
    showAlternateURL() {
      this.showURL =
        this.mode == "page" ||
        this.fieldDefinition?._permissions?.permission?.behavior == "url";
      debug(`showURL`, this.showURL);
    },
    updatePermission(value) {
      this.fieldDefinition._permissions.permission.value = value;
    },
    updateLocalDefinition(updatedValue) {
      this.$set(this, "fieldDefinition", updatedValue);
      debug(`field definition is now `, this.fieldDefinition?._permissions);
    },
    async updateFieldPermissions() {
      debug(
        `emitting update`,
        JSON.stringify(this.fieldDefinition?._permissions)
      );
      if (!this.fieldDefinition._permissions.permission.value) {
        delete this.fieldDefinition._permissions.permission;
      }
      if (this.fieldDefinition._permissions.enabled === false) {
        delete this.fieldDefinition._permissions.enabled;
      }
      this.$emit("update:definition", this.fieldDefinition);
      this.$emit("update", this.fieldDefinition);
    },
    currentPermissions(id) {
      return this.definition?._permissions?.[id] || {};
    },
    fetchPermissionContexts() {
      const component = this;
      const contextPath =
        component.$store?.state?.[component.context]?.context
          ?.application_path || "";
      this.permissionContexts = [
        {
          id: "roles",
          name: "Roles",
          api: `${contextPath}/roles`,
        },
        {
          id: "users",
          name: "Users",
          api: `${contextPath}/users`,
        },
      ];
    },
  },
};
</script>
