import { render, staticRenderFns } from "./PermissionChecks.vue?vue&type=template&id=01fcae42&scoped=true&"
import script from "./PermissionChecks.vue?vue&type=script&lang=js&"
export * from "./PermissionChecks.vue?vue&type=script&lang=js&"
import style0 from "./PermissionChecks.vue?vue&type=style&index=0&id=01fcae42&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01fcae42",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAutocomplete,VCol,VContainer,VDataIterator,VRow,VTextField})
