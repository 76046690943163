<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <v-autocomplete
          aut-add-entity
          dense
          class="behavior_smaller_label"
          :items="items"
          :label="`Add ${this.permission_context.name}`"
          hint="Click the Plus icon to add"
          persistent-hint
          item-value="id"
          item-text="name"
          @keydown.enter.prevent="addEntity"
          v-model="selectedEntity"
          append-outer-icon="mdi-plus"
          @click:append-outer="addEntity"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-data-iterator
      :items="existingEntities"
      :items-per-page.sync="itemsPerPage"
      :search="search"
    >
      <template v-slot:header>
        <v-text-field
          v-model="search"
          clearable
          small
          flat
          hide-details
          class="behavior_smaller_label"
          prepend-inner-icon="mdi-magnify"
          label="Search"
        ></v-text-field>
        <v-row class="font-weight-bold mt-2">
          <v-col cols="12" :lg="colWidth">{{ permission_context.label }}</v-col>
          <v-col
            cols="12"
            :lg="colWidth"
            v-for="(permissionType, i) in permissionTypes"
            :key="i"
          >
            <div class="behavior_centered pa-2">
              <label>{{ permissionType.name }}</label>
            </div>
          </v-col>
        </v-row>
        <hr />
      </template>
      <template v-slot:default="props">
        <div class="behavior_permission_checks">
          <PermissionCheck
            v-for="(entity, i) in props.items"
            :key="i"
            :types="permissionTypes"
            :entity="entity"
            :permissions="entity.permission"
            @update="updateEntityPermissions(entity, $event)"
          />
        </div>
      </template>
    </v-data-iterator>
  </v-container>
</template>
<script>
import { toInteger } from "lodash";
import { PERMISSIONS } from "@/constants";
import { clone, makeServerCall } from "@/util";
import { getPermissionTypes } from "@/components/fields";
const debug = require("debug")("atman.permission_checks"); // eslint-disable-line
import PermissionCheck from "./PermissionCheck";
export default {
  name: "PermissionChecks",
  components: { PermissionCheck },
  props: {
    permission_context: {
      type: Object,
    },
    current_permissions: {
      type: Object,
    },
    definition: {
      type: Object,
    },
    mode: {
      type: String,
    },
  },
  data() {
    return {
      items: [],
      search: "",
      itemsPerPage: 4,
      currentPermissions: clone(this.current_permissions),
      existingEntities: [],
      selectedEntity: null,
    };
  },
  async mounted() {
    debug(
      `Mounted of permission_checks`,
      this.permission_context,
      this.current_permissions
    );
    await this.fetchItems();
    this.prepareEntities();
  },
  computed: {
    colWidth() {
      const result = toInteger(12 / (this.permissionTypes.length + 1));
      debug(`colWidth`, result);
      return result;
    },
    permissionTypes() {
      let result = [...PERMISSIONS];
      if (this.mode != "page") {
        result = getPermissionTypes(this.definition);
      }
      debug(`permissionTypes`, result);
      return result;
    },
    newEntities() {
      return (this.items || []).filter((item) => {
        return !Object.keys(this.currentPermissions).includes(item);
      });
    },
  },
  methods: {
    emitUpdate(definition) {
      debug("update from permission checks", definition);
      this.$emit("update", definition);
    },
    updateEntityPermissions(entity, updatedPermissions) {
      entity.permission = updatedPermissions;
      const currentPermissions = clone(this.currentPermissions);
      (this.existingEntities || []).forEach((item) => {
        currentPermissions[item.id] = item.permission;
      });
      const definition = clone(this.definition);
      definition._permissions[this.permission_context.id] =
        definition._permissions[this.permission_context.id] || {};
      definition._permissions[this.permission_context.id] = currentPermissions;
      this.emitUpdate(definition);
    },
    async fetchItems() {
      const items = (
        await makeServerCall({
          url: this.permission_context.api,
          type: "get",
          params: {
            sort: "_created_date",
            count: 999,
          },
        })
      ).data;
      this.items = items;
    },
    prepareEntities() {
      debug(`items`, this.items, this.currentPermissions);
      const entities = Object.keys(this.currentPermissions || {}).map((key) => {
        debug(`checking for ${key}`, this.items);
        const entity = {
          id: key,
          name: (
            (this.items || []).find(({ id }) => id == key) || { name: key }
          ).name,
          permission: this.currentPermissions[key],
        };
        return entity;
      });
      debug(`existingEntities`, entities);
      this.existingEntities = entities;
    },
    addEntity() {
      debug(`In addEntity`, this.selectedEntity);
      // IF no entity is selected or one already exists, abort.
      if (
        !this.selectedEntity ||
        this.currentPermissions[this.selectedEntity]
      ) {
        return;
      }
      this.currentPermissions[this.selectedEntity] = "RO";
      this.prepareEntities();
      const definition = clone(this.definition);
      definition._permissions[this.permission_context.id] =
        definition._permissions[this.permission_context.id] || {};
      definition._permissions[this.permission_context.id] =
        this.currentPermissions;
      this.emitUpdate(definition);
    },
  },
};
</script>
<style lang="scss" scoped>
.behavior_permission_checks {
  max-height: 400px;
  overflow-x: clip;
  overflow-y: scroll;
}
</style>
